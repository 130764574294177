import React from "react";
import { ThemeContext } from "../layouts";
import Article from "../components/Article";
import Headline from "../components/Article/Headline";
import Seo from "../components/Seo";

import friday from "../images/png/fri.png";
import saturday from "../images/png/sat.png";
import sunday from "../images/png/sun.png";

const Agenda = () => (
  <React.Fragment>
    <ThemeContext.Consumer>
      {theme => (
        <Article theme={theme}>
          <header>
            <Headline title="Agenda" theme={theme} />
          </header>

          <p>
            Here's what we have in mind in case you want an idea of what will go down this weekend... all subject to "God's time" of course.
          </p><br />
          <div className="images">
            <img src={friday} />
            <img src={saturday} />
            <img src={sunday} />
          </div>

          {/* --- STYLES --- */}
          <style jsx>{`
            h2 {
              margin: 0 0 0.5em;
            }
            h2 :global(svg) {
              height: 0.8em;
              fill: ${theme.color.brand.primary};
            }
            .images {
              width: 100%;
              display: flex;
              flex-wrap: wrap;
              justify-content: center;
              align-items: center;
            }
            .images img {
              width: 100%;
              height: auto;
              display: block;
              margin: 5px;
              max-width: 400px;
            }
          `}</style>
        </Article>
      )}
    </ThemeContext.Consumer>
    <Seo />
  </React.Fragment>
);

export default Agenda;
